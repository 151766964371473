import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'modalContent', 'modalSpinner' ];

  showModal(event) {
    this.modalSpinnerTarget.classList.remove('d-none')
    fetch(event.currentTarget.dataset.url)
      .then(response => response.json())
      .then((data) => {
        this.modalSpinnerTarget.classList.add('d-none');
        if (data.content) {
          this.modalContentTarget.innerHTML = data.content;
        } else {
          this.modalContentTarget.innerHTML = "Something went wrong";
        }
      })
  }
}
