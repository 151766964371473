import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['modal', 'img', 'title', 'description', 'date', 'profile', 'email', 'name', 'link'];
  open(event) {
    const initiative = event.currentTarget;
    this.modalTarget.classList.add('d-flex');
    this.modalTarget.classList.remove('d-none');
    this.imgTarget.src = initiative.dataset.photo;
    this.titleTarget.innerHTML = initiative.dataset.title;
    this.descriptionTarget.innerHTML = initiative.dataset.description;
    this.dateTarget.innerHTML = `${initiative.dataset.kinds} | ${initiative.dataset.date}`;
    this.nameTarget.innerHTML = initiative.dataset.name;
    this.nameTarget.href = `/users/${initiative.dataset.username}`;
    this.emailTarget.href = `mailto:${initiative.dataset.email}`;
    this.profileTarget.src = initiative.dataset.profile;
    this.linkTarget.href = initiative.dataset.link;
  }

  close() {
    this.modalTarget.classList.remove('d-flex');
    this.modalTarget.classList.add('d-none');
  }
}
