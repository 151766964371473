import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['form', 'links', 'content'];

  submitComment(event) {
    this._eventHandler(event);
  }

  toggleForm() {
    this.formTarget.classList.toggle('d-none');
    this.linksTarget.classList.toggle('d-none');
    this.contentTarget.classList.toggle('d-none');
  }

  updateComment(event) {
    this._eventHandler(event);
  }

  deleteComment(event) {
    this._eventHandler(event);
  }

  _eventHandler(event) {
    const xhr = event.detail[2];
    document.getElementById('comments_wrapper').outerHTML = xhr.response;
  }
}
