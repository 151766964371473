import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'cta', 'collapsible' ];

  toggleWord() {
    if (this.collapsibleTarget.classList.contains('collapsing')) return;
    const currentWord = this.ctaTarget.innerText;
    this.ctaTarget.innerHTML = this.ctaTarget.dataset.word;
    this.ctaTarget.setAttribute('data-word', currentWord);
  }
}
