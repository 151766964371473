require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("trix");
require("@rails/actiontext");

import 'bootstrap';
import "@mdi/font/css/materialdesignicons.css";
import "select2/dist/css/select2.css";
import "flatpickr/dist/flatpickr.min.css";
import 'intl-tel-input/build/css/intlTelInput.css';
import 'tippy.js/themes/light-border.css';
import 'tippy.js/dist/tippy.css';
import Rails from "@rails/ujs";
import { initTippy } from './components/init_tippy';
import { initTypedJS } from './components/init_typed';
import { requestPermission } from './components/notifications';
import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';

const application = Application.start();
const context = require.context("./../controllers", true, /\.js$/);

document.addEventListener('turbolinks:load', () => {
  Rails.refreshCSRFTokens();
  application.load(definitionsFromContext(context));
  initTippy();
  requestPermission();
});

document.addEventListener('turbolinks:before-cache', () => {
  application.controllers.forEach((controller) => {
    if(typeof controller.teardown === 'function') {
      controller.teardown();
    }
  });
});
