import Typed from 'typed.js';

const initTypedJS = (element) => {
  const options = {
    strings: ['a prosperous',
    'an inclusive',
    'a united',
    'a sustainable',
    'a just',
    'a citizen-led',
    'a democratic',
    'a transparent',
    'a diverse'
    ],
    typeSpeed: 40,
    loop: true,
    loopCount: Infinity,
    smartBackspace: true,
    showCursor: false
  };
  new Typed(element, options);
};

export { initTypedJS };
