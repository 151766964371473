import { Controller } from 'stimulus';
import { initAutosize, updateSize } from './../packs/components/init_autosize';

export default class extends Controller {
  static targets = ['messagesContainer', 'categoriesContainer', 'activeCategory', 'content', 'messagesWrapper', 'form'];

  connect() {
    this.messagesContainerTarget.scroll({ top: this.messagesContainerTarget.scrollHeight });
  }

  renderMessages(event) {
    const xhr = event.detail[2];
    const data = JSON.parse(xhr.response);
    const messageElement = document.getElementById(`message_${data.message_id}`);
    if (data.message && !messageElement) {
      this.messagesContainerTarget.insertAdjacentHTML('beforeend', data.message);
    }
    this.formTarget.outerHTML = data.form;
    initAutosize(this.contentTarget);
    this.messagesContainerTarget.scroll({ top: this.messagesContainerTarget.scrollHeight, behavior: 'smooth' });
    this.contentTarget.focus();
  }
}
