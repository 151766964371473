import { Controller } from 'stimulus';
import { initTippy } from './../packs/components/init_tippy';

export default class extends Controller {
  static targets = ['filterArrow', 'collapsableFilters'];

  rotateArrow() {
    if (!this.collapsableFiltersTarget.classList.contains('collapsing')) {
      this.filterArrowTarget.classList.toggle('rotated-arrow');
    }
  }

  updateInitiative(event) {
    const data = event.detail[0];
    this.element.outerHTML = data.body.innerHTML;
    initTippy();
  }
}
