import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['logo'];
  updateNavbar() {
    this.element.classList.toggle('scrolled-navbar', window.scrollY !== 0);
    if (window.scrollY == 0) {
      this.logoTarget.src = 'https://impactlebanon.s3.eu-west-2.amazonaws.com/static/impactlebanon-logo-white.png';
    } else {
      this.logoTarget.src = 'https://impactlebanon.s3.eu-west-2.amazonaws.com/static/impactlebanon-logo.png';
    }
  }
}
