import { Controller } from 'stimulus';
import { initSelect2 } from './../packs/components/init_select2';

export default class extends Controller {
  static targets = [ 'selector' ]

  connect() {
    initSelect2(this.selectorTarget, {
      height: '100%',
      width: '100%'
    });
  }
}
