import { Controller } from 'stimulus';
import { initAutosize, updateSize } from './../packs/components/init_autosize';

export default class extends Controller {
  static targets = [ 'content' ];

  connect() {
    initAutosize(this.contentTarget);
  }

  expandInput() {
    updateSize(this.contentTarget);
  }
}
