import { Controller } from 'stimulus';

export default class extends Controller {
	static targets = ['answer', 'arrow'];

	rotateArrow() {
    if (!this.answerTarget.classList.contains('collapsing')) {
      this.arrowTarget.classList.toggle('rotated-arrow');
    }
  }
}