import { Controller } from 'stimulus';
import { initSelect2 } from './../packs/components/init_select2';
import { initFlatpickr } from './../packs/components/init_flatpickr';
import { initAutocomplete } from './../packs/components/init_autocomplete';

export default class extends Controller {
  static targets = ['name'];

  updateList(event) {
    const data = event.detail[0];
    document.getElementById('initiative-form').innerHTML = data.body.innerHTML;
    initSelect2('.select2', { width: '100%' });
    initFlatpickr('.date', {
      altInput: true,
      enableTime: true
    });
    const addressInput = document.getElementById('initiative_location');
    initAutocomplete(addressInput);
    this.nameTarget.value = "";
  }
}
