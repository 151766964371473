import { Controller } from 'stimulus';
import { initAutocomplete } from './../packs/components/init_autocomplete';
import { initFlatpickr } from './../packs/components/init_flatpickr';
import { initSelect2 } from './../packs/components/init_select2';
import { initTippy } from './../packs/components/init_tippy';

export default class extends Controller {
  static targets = ['addressInput'];

  connect() {
    initSelect2('.select2', { width: '100%' });
    initFlatpickr('.date', {
      altInput: true,
      enableTime: true
    });
    if (this.hasAddressInputTarget) {
      initAutocomplete(this.addressInputTarget);
    }

  }

  autocomplete() {
    if (this.hasAddressInputTarget) {
      initAutocomplete(this.addressInputTarget);
    }
  }

  updateInitiative(event) {
    const data = event.detail[0];
    this.element.outerHTML = data.body.innerHTML;
    initTippy();
  }
}
