import consumer from './consumer';

document.addEventListener('turbolinks:load', () => {
  consumer.subscriptions.create("NotificationChannel", {
    received(data) {
      const notificationBadges = document.querySelectorAll('.notification-badge');
      const notificationNumbers = document.querySelectorAll('.notification-number');
      notificationBadges.forEach((badge) => {
        badge.classList.remove('d-none');
      });
      notificationNumbers.forEach((number) => {
        number.parentElement.classList.remove('d-none');
        number.innerHTML = parseInt(number.innerText) + 1;
      });
      const notificationContainer = document.querySelector('.notifications-container');
      if (notificationContainer) {
        const notificationWarning = document.querySelector('.js-no-notification-warning');
        if (notificationWarning) notificationWarning.remove();
        notificationContainer.insertAdjacentHTML('afterbegin', data)
      }
    }
  });
});
