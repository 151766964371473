import { Controller } from 'stimulus';
import { initFlatpickr } from './../packs/components/init_flatpickr';

export default class extends Controller {
  connect() {
    initFlatpickr(this.element, {
      altInput: true,
      enableTime: true
    });
  }
}
