import consumer from './consumer';

document.addEventListener('turbolinks:load', () => {
  const messagesContainer = document.querySelector('.messages-container');
  const warningMessage = document.getElementById('first-message-warning');
  if (messagesContainer) {
    const categoryId = messagesContainer.dataset.categoryId;
    const consumerId = parseInt(messagesContainer.dataset.userId);
    consumer.subscriptions.create({ channel: 'CategoryChannel', id: categoryId }, {
      received(data) {
        data = JSON.parse(data);
        const message = document.getElementById(`message_${data.message_id}`);
        if (data.sender_id !== consumerId && !message) {
          messagesContainer.insertAdjacentHTML('beforeend', data.message);
        }
        if (warningMessage) warningMessage.remove();
        const lastMessage = messagesContainer.lastElementChild;
        if (lastMessage) {
          const lastMessageStyles = getComputedStyle(lastMessage);
          const lastMessageMargin = parseInt(lastMessageStyles.marginBottom) + parseInt(lastMessageStyles.marginTop);
          const lastMessageHeight = lastMessage.offsetHeight + lastMessageMargin;
          const visibleMessagesHeight = messagesContainer.offsetHeight;
          const totalMessagesHeight = messagesContainer.scrollHeight;
          const scrollOffset = messagesContainer.scrollTop + visibleMessagesHeight;
          if (totalMessagesHeight - lastMessageHeight <= scrollOffset) {
            messagesContainer.scroll({ top: messagesContainer.scrollHeight, behavior: 'smooth' });
          }
        }
      }
    });
  }
})
